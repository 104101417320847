<template>
  <v-chip
    :color="type == 2?'success':'info'"
    :class="type==2?`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg success--text`:`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg info--text`"
    small
  >
    {{ type == 2 ? $t('full_payment') : $t('installment') }}
  </v-chip>
</template>

<script>
import { mdiCheck, mdiClose } from '@mdi/js'

export default {
  props: { type: String },
  setup() {
    return {
      icons: {
        mdiCheck,
        mdiClose,
      },
    }
  },
}
</script>
