import { i18n } from '@/plugins/i18n'
import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const accountReceivableList = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'orderpay/list',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('get account receivable list err : ', err)

      return {}
    })

  return response
}

const accountReceivableRemove = async id => {
  ////await refreshToken()
  const response = await api
    .delete({
      path: 'orderpay',
      param: `${id}/${i18n.locale}`,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('delete account receivable err : ', err)

      return {}
    })

  return response
}

export default { accountReceivableList, accountReceivableRemove }
