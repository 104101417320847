var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',{staticClass:"px-2"},[_vm._v(" "+_vm._s(_vm.$t('Accounts Receivable'))+" ")]),_c('DateFilters',{on:{"onSendDate":_vm.receiveDate}}),_c('v-row',{staticClass:"px-2"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-text-field',{attrs:{"label":_vm.$t('search'),"outlined":"","dense":""},model:{value:(_vm.searchtext),callback:function ($$v) {_vm.searchtext=$$v},expression:"searchtext"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-select',{attrs:{"items":_vm.receptStatusList,"label":_vm.$t('payment_status'),"item-text":_vm.$i18n.locale,"outlined":"","dense":"","item-value":"value"},model:{value:(_vm.order_pay_id),callback:function ($$v) {_vm.order_pay_id=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"order_pay_id"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-select',{attrs:{"items":_vm.statusPayments,"label":_vm.$t('pay_status'),"item-text":_vm.$i18n.locale,"outlined":"","dense":"","item-value":"value"},model:{value:(_vm.orderpay_status_id),callback:function ($$v) {_vm.orderpay_status_id=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"orderpay_status_id"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.columns,"items":_vm.dataTableList,"options":_vm.options,"footer-props":_vm.footer,"loading":_vm.loading,"disable-sort":"","hide-default-footer":"","loading-text":_vm.$t('data_loading'),"no-data-text":_vm.$t('no_information')},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.order_id_pri",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(_vm.segmentId + index + 1)+" ")]}},{key:"item.order_id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"font-weight-medium text-decoration-none",attrs:{"to":{ name: 'order-detail', params: { id: item.order_id_pri } }}},[_vm._v(" "+_vm._s(item.order_id)+" ")])]}},{key:"item.customer_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.customer_name)+" "),_c('br'),(item.order_deposit_id == '1')?_c('span',{staticClass:"primary--text"},[(item.orderpay_period == '0')?_c('span',[_vm._v(" "+_vm._s(_vm.$t('deposit'))+" : ")]):_vm._e(),_vm._v(" "+_vm._s(item.order_deposit_bank_name)+" ")]):_vm._e()]}},{key:"item.net_amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPrice")(item.net_amount))+" "),_c('br'),(item.order_deposit_id == '1')?_c('span',{staticClass:"primary--text"},[(item.orderpay_period == '0')?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatPrice")(item.order_deposit_amount))+" ")]):_vm._e()]):_vm._e()]}},{key:"item.order_pay_id",fn:function(ref){
var item = ref.item;
return [_c('PaymentType',{attrs:{"type":item.order_pay_id}})]}},{key:"item.orderpay_current",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPrice")(item.orderpay_current))+" ")]}},{key:"item.orderpay_all",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPrice")(item.orderpay_all))+" ")]}},{key:"item.orderpay_balance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPrice")(item.orderpay_balance))+" ")]}},{key:"item.orderpay_status_id",fn:function(ref){
var item = ref.item;
return [_c('PaymentStatus',{attrs:{"status":item.orderpay_status_id}})]}},{key:"item.orderpay_modify",fn:function(ref){
var item = ref.item;
return [_c('ConversDateTime',{attrs:{"datetime":item.orderpay_modify}})]}},{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"nudge-width":100,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-2",attrs:{"color":"primary","dark":"","small":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('option'))),_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiMenuDownOutline)+" ")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',[(item.orderpay_status_id == 1)?_c('v-list-item',{on:{"click":function($event){_vm.orderId = item.order_id_pri
                  _vm.isPrintReceipt = true
                  _vm.statusPath = 'receipt'}}},[_c('v-list-item-subtitle',[_c('v-icon',{staticClass:"me-1",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiPrinter)+" ")]),_vm._v(_vm._s(_vm.$t('print_receipt'))+" ")],1)],1):_vm._e(),(item.orderpay_status_id == 2)?_c('v-list-item',{on:{"click":function($event){_vm.orderPayId = item.orderpay_id
                  _vm.orderId = item.order_id_pri
                  _vm.isPrintReceipt = true
                  _vm.statusPath = 'invoice2'}}},[_c('v-list-item-subtitle',[_c('v-icon',{staticClass:"me-1",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiPrinter)+" ")]),_vm._v(_vm._s(_vm.$t('print_invoice'))+" ")],1)],1):_vm._e(),(item.orderpay_delete_status_id == 1)?_c('v-list-item',{on:{"click":function($event){_vm.orderpay_id = item.orderpay_id
                  _vm.isUserComfirm = true}}},[_c('v-list-item-subtitle',[_c('v-icon',{staticClass:"me-1",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")]),_vm._v(_vm._s(_vm.$t('delete'))+" ")],1)],1):_vm._e()],1)],1)],1)]}}],null,true)}),_c('v-divider'),_c('Pagination',{attrs:{"page-data":_vm.options.page,"page-count":_vm.totalPage,"segment-id":+_vm.segmentId,"count-list":_vm.dataTableList.length,"total":+_vm.totalDataTableList},on:{"pageChanged":function (page) {
          _vm.options.page = page
          _vm.fetchDataTable()
        },"itemChanged":function (items) {
          _vm.options.itemsPerPage = items
          _vm.options.page = 1
          _vm.fetchDataTable()
        }}})],1),_c('UserConfirm',{on:{"onSend":_vm.removeAccountReceivable},model:{value:(_vm.isUserComfirm),callback:function ($$v) {_vm.isUserComfirm=$$v},expression:"isUserComfirm"}}),_c('ReceiptOptions',{attrs:{"order-id":_vm.orderId,"status-path":_vm.statusPath,"order-pay-id":_vm.orderPayId},model:{value:(_vm.isPrintReceipt),callback:function ($$v) {_vm.isPrintReceipt=$$v},expression:"isPrintReceipt"}}),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.isDelete),callback:function ($$v) {_vm.isDelete=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"isDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"p-2"},[_c('v-spacer'),_vm._v(" "+_vm._s(_vm.$t('confirm_deletion'))+" "),_c('v-spacer')],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"loading":_vm.loadingDelete,"disabled":_vm.loadingDelete,"color":"error"},on:{"click":function($event){return _vm.removeAccountReceivable()}}},[_vm._v(" "+_vm._s(_vm.$t('confirm'))+" ")]),_c('v-btn',{attrs:{"color":"secondary","outlined":""},on:{"click":function($event){_vm.isDelete = false}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('v-spacer')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }