<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        {{ $t('Accounts Receivable') }}
      </v-card-title>
      <DateFilters @onSendDate="receiveDate"></DateFilters>
      <v-row class="px-2">
        <v-col cols="12" md="6" class="py-0" lg="4">
          <v-text-field v-model="searchtext" :label="$t('search')" outlined dense></v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="py-0" lg="4">
          <v-select
            v-model.trim="order_pay_id"
            :items="receptStatusList"
            :label="$t('payment_status')"
            :item-text="$i18n.locale"
            outlined
            dense
            item-value="value"
          ></v-select>
        </v-col>
        <v-col cols="12" md="6" lg="4" class="py-0">
          <v-select
            v-model.trim="orderpay_status_id"
            :items="statusPayments"
            :label="$t('pay_status')"
            :item-text="$i18n.locale"
            outlined
            dense
            item-value="value"
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table
        :headers="columns"
        :items="dataTableList"
        :options.sync="options"
        :footer-props="footer"
        :loading="loading"
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.order_id_pri`]="{ index }">
          {{ segmentId + index + 1 }}
        </template>
        <template v-slot:[`item.order_id`]="{ item }">
          <router-link
            :to="{ name: 'order-detail', params: { id: item.order_id_pri } }"
            class="font-weight-medium text-decoration-none"
          >
            {{ item.order_id }}
          </router-link>
        </template>
        <template v-slot:[`item.customer_name`]="{ item }">
          {{ item.customer_name }} <br />
          <span v-if="item.order_deposit_id == '1'" class="primary--text">
            <span v-if="item.orderpay_period == '0'"> {{ $t('deposit') }} : </span> {{ item.order_deposit_bank_name }}
          </span>
        </template>
        <template v-slot:[`item.net_amount`]="{ item }">
          {{ item.net_amount | formatPrice }} <br />
          <span v-if="item.order_deposit_id == '1'" class="primary--text">
            <span v-if="item.orderpay_period == '0'">
              {{ item.order_deposit_amount | formatPrice }}
            </span>
          </span>
        </template>
        <template v-slot:[`item.order_pay_id`]="{ item }">
          <PaymentType :type="item.order_pay_id"></PaymentType>
        </template>
        <template v-slot:[`item.orderpay_current`]="{ item }">
          {{ item.orderpay_current | formatPrice }}
        </template>
        <template v-slot:[`item.orderpay_all`]="{ item }">
          {{ item.orderpay_all | formatPrice }}
        </template>
        <template v-slot:[`item.orderpay_balance`]="{ item }">
          {{ item.orderpay_balance | formatPrice }}
        </template>
        <template v-slot:[`item.orderpay_status_id`]="{ item }">
          <PaymentStatus :status="item.orderpay_status_id" />
        </template>
        <template v-slot:[`item.orderpay_modify`]="{ item }">
          <ConversDateTime :datetime="item.orderpay_modify"></ConversDateTime>
        </template>
        <template v-slot:[`item.options`]="{ item }">
          <v-menu :nudge-width="100" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" class="px-2" dark small v-bind="attrs" v-on="on">
                {{ $t('option')
                }}<v-icon small>
                  {{ icons.mdiMenuDownOutline }}
                </v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item-group>
                <v-list-item
                  v-if="item.orderpay_status_id == 1"
                  @click="
                    orderId = item.order_id_pri
                    isPrintReceipt = true
                    statusPath = 'receipt'
                  "
                >
                  <v-list-item-subtitle>
                    <v-icon small class="me-1"> {{ icons.mdiPrinter }} </v-icon>{{ $t('print_receipt') }}
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item
                  v-if="item.orderpay_status_id == 2"
                  @click="
                    orderPayId = item.orderpay_id
                    orderId = item.order_id_pri
                    isPrintReceipt = true
                    statusPath = 'invoice2'
                  "
                >
                  <v-list-item-subtitle>
                    <v-icon small class="me-1"> {{ icons.mdiPrinter }} </v-icon>{{ $t('print_invoice') }}
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item
                  v-if="item.orderpay_delete_status_id == 1"
                  @click="
                    orderpay_id = item.orderpay_id
                    isUserComfirm = true
                  "
                >
                  <v-list-item-subtitle>
                    <v-icon small class="me-1"> {{ icons.mdiDeleteOutline }} </v-icon>{{ $t('delete') }}
                  </v-list-item-subtitle>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <Pagination
        :page-data="options.page"
        :page-count="totalPage"
        :segment-id="+segmentId"
        :count-list="dataTableList.length"
        :total="+totalDataTableList"
        @pageChanged="
          page => {
            options.page = page
            fetchDataTable()
          }
        "
        @itemChanged="
          items => {
            options.itemsPerPage = items
            options.page = 1
            fetchDataTable()
          }
        "
      />
    </v-card>
    <UserConfirm v-model="isUserComfirm" @onSend="removeAccountReceivable" />
    <ReceiptOptions v-model="isPrintReceipt" :order-id="orderId" :status-path="statusPath" :order-pay-id="orderPayId" />
    <v-dialog v-model.trim="isDelete" persistent max-width="290">
      <v-card>
        <v-card-title class="p-2">
          <v-spacer></v-spacer>
          {{ $t('confirm_deletion') }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :loading="loadingDelete" :disabled="loadingDelete" color="error" @click="removeAccountReceivable()">
            {{ $t('confirm') }}
          </v-btn>
          <v-btn color="secondary" outlined @click="isDelete = false">
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mdiPrinter, mdiDeleteOutline, mdiMenuDownOutline } from '@mdi/js'
import useAccountsReceivable from './useAccountsReceivable'
import DateFilters from '@/components/basicComponents/DateFilters.vue'
import ConversDateTime from '@/components/basicComponents/ConversDateTime.vue'
import { formatPrice } from '@/plugins/filters'
import PaymentType from '@/components/basicComponents/PaymentType.vue'
import PaymentStatus from '@/components/basicComponents/PaymentStatus.vue'
import UserConfirm from '@/components/basicComponents/UserConfirm.vue'
import ReceiptOptions from '@/components/prints/ReceiptOptions.vue'
import Pagination from '@/components/basicComponents/Pagination.vue'

export default {
  filters: {
    formatPrice,
  },
  components: {
    ReceiptOptions,
    DateFilters,
    ConversDateTime,
    Pagination,
    PaymentType,
    PaymentStatus,
    UserConfirm,
  },
  setup() {
    return {
      ...useAccountsReceivable(),

      icons: {
        mdiPrinter,
        mdiDeleteOutline,
        mdiMenuDownOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped></style>
