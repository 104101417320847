<template>
  <v-chip
    :color="status == 1?'success':'info'"
    :class="status==1?`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg success--text`:`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg warning--text`"
    small
  >
    {{ status == 1 ? $t('complete_payment') : $t('owe') }}
  </v-chip>
</template>

<script>
import { mdiCheck, mdiClose } from '@mdi/js'

export default {
  props: { status: String },
  setup() {
    return {
      icons: {
        mdiCheck,
        mdiClose,
      },
    }
  },
}
</script>
