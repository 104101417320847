import PayStatus from '@/@fake-db/data/PayStatus.json'
import receptStatus from '@/@fake-db/data/receptStatus.json'
import accountReceivable from '@/api/receipt/accountReceivable'
import footerDataTables from '@/components/basicComponents/footerDataTables.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'
import { i18n } from '@/plugins/i18n'
import store from '@/store'
import { onMounted, ref, watch } from '@vue/composition-api'

export default function useAccountsReceivable() {
  // search valiables
  const searchtext = ref('')
  const receptStatusList = ref(receptStatus.data)
  const order_pay_id = ref('')
  const statusPayments = ref(PayStatus.data)
  const orderpay_status_id = ref('')
  const date_start = ref('')
  const date_end = ref('')
  const isDateChange = ref(false)

  // dataTalble valiable
  const loading = ref(false)
  const dataTableList = ref([])
  const totalDataTableList = ref(0)
  const totalPage = ref(0)
  const columns = ref([
    {
      text: '#',
      align: 'start',
      sortable: false,
      value: 'order_id_pri',
      width: 50,
    },
    {
      text: i18n.t('a_number'),
      value: 'order_id',
      width: 100,
    },
    {
      text: i18n.t('customer'),
      value: 'customer_name',
      width: 200,
    },
    {
      text: i18n.t('net_amount'),
      value: 'net_amount',
      align: 'end',
      width: 170,
    },
    {
      text: i18n.t('payment_status'),
      value: 'order_pay_id',
      width: 170,
    },
    {
      text: i18n.t('period'),
      value: 'orderpay_period',
      width: 100,
      align: 'center',
    },
    {
      text: i18n.t('payments'),
      value: 'orderpay_current',
      width: 120,
      align: 'end',
    },
    {
      text: i18n.t('total_payment'),
      value: 'orderpay_all',
      width: 150,
      align: 'end',
    },
    {
      text: i18n.t('outstanding_balance'),
      value: 'orderpay_balance',
      width: 120,
      align: 'end',
    },
    {
      text: i18n.t('pay_status'),
      align: 'center',
      value: 'orderpay_status_id',
      width: 160,
    },
    {
      text: i18n.t('date'),
      value: 'orderpay_modify',
      width: 180,
      align: 'center',
    },
    {
      text: i18n.t('option'),
      value: 'options',
      align: 'center fixed',
      width: 120,
    },
  ])
  const segmentId = ref(0)
  const options = ref({})
  const footer = ref(i18n.locale == 'en' ? footerDataTablesEN : footerDataTables)

  const orderpay_id = ref('')
  const isUserComfirm = ref(false)
  const isDelete = ref(false)
  const loadingDelete = ref(false)
  const isPrintReceipt = ref(false)
  const orderId = ref('')
  const orderPayId = ref('')
  const statusPath = ref('')

  const { accountReceivableList, accountReceivableRemove } = accountReceivable

  const removeAccountReceivable = () => {
    loadingDelete.value = true
    accountReceivableRemove(orderpay_id.value).then(res => {
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      fetchDataTable()
      isDelete.value = false
      loadingDelete.value = false
    })
  }

  const fetchDataTable = event => {
    loading.value = true
    accountReceivableList({
      searchtext: searchtext.value,
      order_pay_id: order_pay_id.value,
      orderpay_status_id: orderpay_status_id.value,
      date_start: date_start.value,
      date_end: date_end.value,
      active_page: options.value.page,
      per_page: options.value.itemsPerPage == -1 ? '0' : options.value.itemsPerPage,
    }).then(res => {
      const { data, segment, count_of_page } = res
      dataTableList.value = data
      segmentId.value = segment
      totalPage.value = count_of_page
      if (event == 'add') {
        options.value.page = count_of_page
      } else if (event == 'delete') {
        if (+options.value.page > +count_of_page) {
          options.value.page = count_of_page
          fetchDataTable()
        }
      }
      loading.value = false
    })
  }

  const receiveDate = (start, end) => {
    date_start.value = start
    date_end.value = end
    isDateChange.value = !isDateChange.value
  }

  watch([searchtext, isDateChange, order_pay_id, orderpay_status_id, options], (newvalue, oldvalue) => {
    if (
      newvalue[0] !== oldvalue[0] ||
      newvalue[1] !== oldvalue[1] ||
      newvalue[2] !== oldvalue[2] ||
      newvalue[3] !== oldvalue[3]
    ) {
      options.value.page = 1
      fetchDataTable()
    }
  })

  return {
    orderpay_id,
    isUserComfirm,
    removeAccountReceivable,
    receiveDate,
    searchtext,
    receptStatusList,
    order_pay_id,
    statusPayments,
    orderpay_status_id,
    orderPayId,
    loading,
    dataTableList,
    totalDataTableList,
    isDelete,
    columns,
    loadingDelete,
    segmentId,
    options,
    footer,
    fetchDataTable,
    totalPage,
    isPrintReceipt,
    orderId,
    statusPath,
  }
}
